.evr {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #353535;
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  overflow: hidden; }
  .evr.iphoneEmulateFS {
    padding-bottom: 1px; }
  .evr:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing; }
  .evr.isLoading canvas {
    display: none; }
  .evr .evr-logs {
    position: absolute;
    color: #fff;
    top: 50%;
    left: 50%;
    z-index: 100; }
  .evr .evr-ui {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff; }
    .evr .evr-ui .evr-stats {
      left: 100% !important;
      z-index: 5 !important;
      transform: translateX(-100%);
      width: 80px;
      height: 48px; }
    .evr .evr-ui .ui-spaceplans {
      position: absolute;
      cursor: default;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: 80%;
      max-width: 1200px;
      max-height: calc(100vh - 136px);
      z-index: 200;
      display: flex;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      pointer-events: all;
      overflow: hidden;
      align-items: center; }
      .evr .evr-ui .ui-spaceplans .spaceplans-content {
        position: relative;
        align-self: stretch;
        flex-grow: 1;
        flex-shrink: 0;
        margin: 50px; }
        .evr .evr-ui .ui-spaceplans .spaceplans-content img.spaceplans-background {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%); }
        .evr .evr-ui .ui-spaceplans .spaceplans-content .spaceplans-pin {
          position: absolute;
          display: block;
          width: 35px;
          height: 35px;
          transform: translate(-50%, -100%);
          cursor: pointer;
          z-index: 5;
          pointer-events: all; }
          .evr .evr-ui .ui-spaceplans .spaceplans-content .spaceplans-pin img {
            width: 100%; }
          .evr .evr-ui .ui-spaceplans .spaceplans-content .spaceplans-pin img:first-child {
            display: block; }
          .evr .evr-ui .ui-spaceplans .spaceplans-content .spaceplans-pin img:last-child {
            display: none; }
          .evr .evr-ui .ui-spaceplans .spaceplans-content .spaceplans-pin.isSelected {
            width: 45px;
            height: 45px; }
            .evr .evr-ui .ui-spaceplans .spaceplans-content .spaceplans-pin.isSelected img:first-child {
              display: none; }
            .evr .evr-ui .ui-spaceplans .spaceplans-content .spaceplans-pin.isSelected img:last-child {
              display: block; }
      .evr .evr-ui .ui-spaceplans .spaceplans-menu {
        width: 20%;
        flex-grow: 0;
        flex-shrink: 0;
        margin: 30px; }
        .evr .evr-ui .ui-spaceplans .spaceplans-menu .menu-item {
          position: relative;
          display: block;
          float: left;
          width: 100%;
          box-sizing: border-box;
          pointer-events: all;
          cursor: pointer;
          border-radius: 6px;
          overflow: hidden;
          margin-bottom: 20px;
          height: 150px;
          height: calc(20vh);
          max-height: 150px; }
          .evr .evr-ui .ui-spaceplans .spaceplans-menu .menu-item .item-background {
            display: block;
            width: 100%;
            height: 100%;
            transition: all 0.2s; }
            .evr .evr-ui .ui-spaceplans .spaceplans-menu .menu-item .item-background img {
              width: 100%;
              height: 100%; }
            .evr .evr-ui .ui-spaceplans .spaceplans-menu .menu-item .item-background::after {
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.7);
              position: absolute;
              top: 0;
              left: 0;
              transition: all 0.2s; }
          .evr .evr-ui .ui-spaceplans .spaceplans-menu .menu-item .item-name {
            position: absolute;
            top: 50%;
            width: 100%;
            text-align: center;
            color: #000;
            font-size: 18px;
            font-weight: 700;
            transform: translateY(-50%);
            overflow: hidden;
            height: 27px;
            transition: all 0.2s;
            user-select: none; }
          .evr .evr-ui .ui-spaceplans .spaceplans-menu .menu-item.isSelected .item-background::after {
            background: rgba(255, 0, 52, 0.7); }
      .evr .evr-ui .ui-spaceplans .spaceplans-title {
        position: absolute;
        top: -40px;
        left: 0;
        width: 100%;
        height: 40px;
        text-align: center;
        z-index: 20;
        font-size: 28px; }
      .evr .evr-ui .ui-spaceplans .spaceplans-description {
        position: absolute;
        bottom: -50px;
        left: 0;
        width: 100%;
        height: 40px;
        text-align: center;
        z-index: 20;
        font-size: 18px; }
      .evr .evr-ui .ui-spaceplans .spaceplans-name {
        width: 100%;
        margin: 40px; }
      .evr .evr-ui .ui-spaceplans .spaceplans-close {
        position: absolute;
        display: inline-block;
        overflow: hidden;
        cursor: pointer;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        margin: 8px; }
        .evr .evr-ui .ui-spaceplans .spaceplans-close:hover::before, .evr .evr-ui .ui-spaceplans .spaceplans-close:hover::after {
          background: rgba(255, 79, 115, 0.7); }
        .evr .evr-ui .ui-spaceplans .spaceplans-close::before, .evr .evr-ui .ui-spaceplans .spaceplans-close::after {
          content: '';
          position: absolute;
          height: 1px;
          width: 100%;
          top: 50%;
          left: 0;
          margin-top: -1px;
          background: #fff; }
        .evr .evr-ui .ui-spaceplans .spaceplans-close::before {
          transform: rotate(45deg); }
        .evr .evr-ui .ui-spaceplans .spaceplans-close::after {
          transform: rotate(-45deg); }
    .evr .evr-ui .ui-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      z-index: 100;
      pointer-events: all; }
    .evr .evr-ui .evr-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 200; }
      .evr .evr-ui .evr-loader .loader-progress {
        min-width: 70px;
        min-height: 70px;
        width: 7vw;
        height: 7vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute; }
        .evr .evr-ui .evr-loader .loader-progress path.st1 {
          stroke-dashArray: 5000;
          stroke-dashOffset: 5000;
          transition: 0.2s all; }
        .evr .evr-ui .evr-loader .loader-progress svg {
          position: absolute;
          width: auto;
          height: 10rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
      .evr .evr-ui .evr-loader.isError .loader-circle {
        border-left-color: #f00; }
    .evr .evr-ui .isHidden {
      display: none; }
  .evr .evr-fontsContainer {
    position: fixed;
    top: 100vh;
    left: 100vw; }

.evr-fullscreenVideo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: black;
  height: 100vh; }
  .evr-fullscreenVideo .evr-video {
    width: 100%;
    height: 100%; }
  .evr-fullscreenVideo .evr-videoClose {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px; }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@font-face {
  font-family: Futura;
  src: url("/static/player/fonts/Futura-Regular.ttf"); }
